<template>
    <div
            class="app-container calendar-list-container evaBg"
    >
        <div class="filter-container">
<!--            <el-row :gutter="15">-->
<!--                <el-col :span="6">-->
<!--                    <el-input-->
<!--                            v-model="query.qr_count"-->
<!--                            placeholder="输入(范围查询请用'/'隔开)"-->
<!--                    >-->
<!--                        <template slot="prepend">二维码编号</template>-->
<!--                    </el-input>-->
<!--                </el-col>-->
<!--                <el-col :span="6">-->
<!--                    <el-date-picker-->
<!--                            v-model="timeRange"-->
<!--                            type="daterange"-->
<!--                            range-separator="至"-->
<!--                            start-placeholder="开始日期"-->
<!--                            end-placeholder="结束日期"-->
<!--                            unlink-panels-->
<!--                            value-format="yyyy-MM-dd"-->
<!--                            clearable-->
<!--                    ></el-date-picker>-->
<!--                </el-col>-->
<!--                <el-col :span="4">-->
<!--                    <el-button-->
<!--                            style="margin-left:15px;"-->
<!--                            type="primary"-->
<!--                            @click="submit()"-->
<!--                    >统计</el-button>-->
<!--                    <el-button-->
<!--                            style="margin-left:10px;"-->
<!--                            @click="reset()"-->
<!--                    >重置</el-button>-->
<!--                </el-col>-->
<!--            </el-row>-->
        </div>
        <el-row
                v-if="listData.length>0"
                :gutter="10"
        >
            <el-col
                    v-for="(item,index) in listData"
                    :key="index"
                    :span="8"
                    style="margin-bottom:10px;"
            >
                <div style="padding: 30px">
                    <el-card
                            class="box-card"
                            shadow="hover"
                    >
                        <div
                                slot="header"
                                class="clearfix"
                                style="text-align:center;height:40px;"
                        >
                            <span style="color:#fff">{{item.name}}</span>
                        </div>
                        <div >
                            <ve-ring
                                    :data="item.chartData"
                                    :settings="chartSettings"
                                    :extend="chartExtend"
                            ></ve-ring>
                        </div>
                    </el-card>

                </div>
            </el-col>
        </el-row>
        <div
                v-else
                style="text-align:center"
        >无数据</div>
    </div>
</template>

<script>
    import api from "../../../util/extra-api";
    import { local} from "../../../util/util";
    export default {
        data() {
            this.chartSettings = {
                roseType: "radius"
            };
            this.chartExtend = {
                legend: {
                    textStyle: {
                        color: "#ffffff" //字体颜色
                    }
                }
            };
            return {
                listData:[],
                query: {
                    qr_count: "",
                    start_time: "",
                    end_time: ""
                },
                timeRange: "",
            };
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                api.get('/v1/pc/evaluation/evaluateChartData',{companyId: local.get('company_id')}).then(res =>{
                    if(res.code === 200){
                        res.data.forEach(r => {
                            r.chartData = {};
                            r.chartData.columns = ["key", "value"];
                            r.chartData.rows = r.data;
                            delete r.data;
                        });
                        this.listData = res.data;
                    }
                })
            },
            reset() {
                this.query = {
                    qr_count: "",
                    start_time: "",
                    end_time: ""
                };
                this.timeRange = "";
                this.getData();
            },
            submit() {
                if (this.timeRange) {
                    this.query.start_time = this.timeRange[0];
                    this.query.end_time = this.timeRange[1];
                }
                this.getData();
            }
        }
    };
</script>
<style>
    .el-card {
        background-color: transparent;
    }
    .evaBg{
        transform-origin: 0px 0px 0px;
        background-image: url("../../../assets/image/mapbg.png");
        background-size: cover;
        background-position: 50% 0;
        background-color: rgb(0, 0, 0);
        min-width: auto;
        width: 100%;
        min-height: auto;
        height: 100%;
        overflow: hidden;
        overflow-x: hidden;
    }
</style>
